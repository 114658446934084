import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './LoginPage.css';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const lowercaseUsername = username.toLowerCase();
    const lowercasePassword = password.toLowerCase();

    if (lowercaseUsername === 'robertmichael' && lowercasePassword === 'baldhead1') {
      // Redirect to another page
      navigate('/profile');
    } else {
      // Show error message
      setShowError(true);
    }
  };

  return (
    <div className="login-page">
      <div className="container">
      <Link to='/'>
        <img
          className="logo"
          src={process.env.PUBLIC_URL + `logo.png`}
          alt="Site Logo"
        />
        </Link>
        <form className="login-form" onSubmit={handleSubmit}>
          <h2 className="font-bold text-xl">Login now and shine bright!</h2>
          {showError && (
            <div className="error-message text-red-700 text-center">Invalid username or password! <br></br>Please try again.</div>
          )}
          <div className="mb-4">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              className="input-field"
              value={username}
              onChange={handleUsernameChange}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              className="input-field"
              value={password}
              onChange={handlePasswordChange}
              required
            />
          </div>
          <button type="submit" className="login-button">Login</button>
          <div className="text-center mt-4">
            <p className="reset-password-link">Forget Password? <br></br>Contact Admin to Reset Password</p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
