import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-black py-4">
      <div className="mx-auto text-white text-center">
        <p className="text-sm">
          Copyright © Influx Energy {new Date().getFullYear()}. All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
