import './App.css';
import Footer from './Components/Footer';
import LoginPage from './Components/LoginPage';

function App() {
  return (
    <div className="App">
      <LoginPage/>
      <Footer/>
        
    </div>
  );
}

export default App;
