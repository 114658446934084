import React from 'react';
import './ProfilePage.css';
import {Link} from 'react-router-dom'
import Footer from './Footer';

const ProfilePage = () => {
  return (
    <>
    <div className='profile-page-bgn'>
    <Link to='/'>
         <img
          className="logo"
          src={process.env.PUBLIC_URL + `logo.png`}
          alt="Site Logo"
        />
        </Link>
        <h1 className='text-center text-bold text-2xl text-white py-8 bg-green-700 mb-4'>MICHAEL ROBERT</h1>
    <div className="profile-page">
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/3 lg:w-2/5 xl:w-3/7">
          <img className="profile-image" src={process.env.PUBLIC_URL + `profile-image.jpg`} alt="Profile" />
          <h2 className="text-xl font-bold mt-4">MICHAEL ROBERT</h2>
          <h3 className="text-lg text-gray-600">Solar Panel Engineer</h3>

          <div className="section">
            <h4 className="text-lg font-bold mt-6">Skills</h4>
            <p>Project Management, Technical Support,<br></br> Engineering Projects, Capital Projects,<br></br> Control Systems, Design Concepts, <br></br>Technical Reports, Autocad</p>
          </div>

          <div className="section">
            <h4 className="text-lg font-bold mt-6">Project History</h4>
            <ul>
              <li>Designed all aspects of electrical engineering for a portfolio of 20 MW of renewable energy projects including commercial and utility-scale plants.</li>
              <li>Collaborated with customers to achieve budget goals while providing high-efficiency products and system designs.</li>
              <li>Completed set up and tuning to achieve optimal efficiency of solar projects in accordance with customer specifications.</li>
              <li>Provided technical project management for Utility Grade Solar Projects in South Africa.</li>
              <li>Utilized PVsyst, Helioscope, Aurora Solar for PV system design.</li>
              <li>Performed AutoCAD designs, PVSyst studies, BOMs, 1 and 3-line drawings for systems above 300kW.</li>
            </ul>
          </div>

          <div className="section">
            <h4 className="text-lg font-bold mt-6">Education</h4>
            <p>Associate Mechanical Engineering</p>
            <p>University of KwaZulu-Natal</p>
            <p>Durban, Westville, Pinetown and Pietermaritzburg, KwaZulu-Natal, South Africa.</p>
          </div>

          <div className="section">
            <h4 className="text-lg font-bold mt-6">Certifications</h4>
            <ul>
              <li>Project Management Professional</li>
              <li>Certified Energy Manager</li>
              <li>Certified Quality Engineer</li>
              <li>Engineering Management Certification Fundamentals</li>
              <li>Program Management Professional</li>
              <li>Software Engineering Master Certification</li>
              <li>LEED AP Building Design + Construction</li>
            </ul>
          </div>
        </div>

        <div className="w-full md:w-2/3 lg:w-3/5 xl:w-4/7">
          <div className="section">
            <h4 className="text-lg font-bold">About Me</h4>
            <p>Welcome to my website! My name is Michael Robert, and I am passionate about harnessing the power of the sun to create a sustainable and clean energy future. As a solar panel engineer, I strive to make a positive impact on our environment and contribute to the global shift towards renewable energy.</p>
          </div>

          <div className="section">
            <h4 className="text-lg font-bold mt-6">Values and Principles</h4>
            <p>At the core of my work are values of environmental stewardship, innovation, and collaboration. I believe that we have a responsibility to protect and preserve our planet for future generations. By embracing sustainable technologies like solar power, we can reduce our carbon footprint and mitigate the effects of climate change. I am committed to promoting and advancing these principles in every aspect of my professional journey.</p>
          </div>

          <div className="section">
            <h4 className="text-lg font-bold mt-6">Journey and Expertise</h4>
            <p>My fascination with solar energy began during my early years in college, where I pursued a degree in Electrical engineering. During my studies, I was captivated by the immense potential of solar power and its ability to transform the way we generate electricity. This sparked a deep curiosity, which led me to specialize in solar panel design and engineering.</p>
            <p>Over the years, I have gained extensive experience in the field, working on diverse projects ranging from residential solar installations to large-scale solar farms for my country and countries like Mali, Senegal, and Benin Republic. Each project has taught me valuable lessons about optimizing solar panel efficiency, integrating innovative technologies, and ensuring system reliability. Through my continuous pursuit of knowledge and staying up-to-date with industry advancements, I am able to provide cutting-edge solutions tailored to meet the unique needs of my clients.</p>
          </div>

          <div className="section">
            <h4 className="text-lg font-bold mt-6">Importance of Solar Energy</h4>
            <p>Solar energy is more than just a means of generating electricity; it represents a brighter and more sustainable future. By harnessing the power of the sun, we can reduce our dependence on fossil fuels, combat climate change, and create a cleaner environment for generations to come. Solar panels offer an efficient and cost-effective way to produce renewable energy, making it accessible to both homeowners and businesses.</p>
            <p>My dedication to solar engineering stems from the belief that everyone should have the opportunity to contribute to a greener world. Whether you are considering installing solar panels for your home or implementing a solar energy system for your organization, I am here to guide you through the process, provide expert advice, and deliver reliable solutions that align with your goals.</p>
            <p>Join me in embracing the sun's boundless energy, and together, let's create a sustainable future.</p>
          </div>
        </div>
      </div>
    </div>
    </div>
    <Footer/>
    </>
  );
};

export default ProfilePage;
